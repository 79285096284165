import glConf from './glConf'
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glType from './glType';
import glParam from './glParam';


export default class attentionFootPosition extends object3d {

  constructor(opt) {

    super(opt)

    this._foot = undefined
    this._dummy= undefined
    this._pants= undefined
    this._anm = undefined
    this._dummyIndex= undefined
  }


  init() {

    super.init();

    this._anm = false
    this._dummyIndex= 2;

    this._foot = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'attentionHem_foot.png')
    });
    this._foot.init();
    this._foot.setSize(321, 521);
    this._foot.alpha(0)
    this.add(this._foot);
    

    // maskの代わり
    this._dummy = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'attentionHem_dummy.png')
    });
    this._dummy.init();
    this._dummy.setSize(326, 214);
    this.add(this._dummy);

    this._pants = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'attentionHem_pant.png')
    });
    this._pants.init();
    this._pants.setSize(326, 521);
    this._pants.alpha(0)
    this.add(this._pants);

    this.resize();

  }

  show(opt) {
    this._pants.fadeIn({
        duration: opt.duration,
        delay: opt.delay,
        ease: opt.ease,
        onComplete : () => {
          this.startAmin()
        }
    })
    this._foot.fadeIn({
        duration: opt.duration - 0.1,
        delay: opt.delay + 0.2,
        ease: opt.ease
    })

  }


  hide(opt) {
    this._anm = false

    console.log("OPT FOOT, PANTS", opt )

    TweenMax.to(this._foot.position, opt.duration, {
      x:this._foot.position.x + opt.dx,
      delay:opt.interval,
      ease:opt.ease
    })
    this._foot.fadeOut({
      duration: opt.duration,
      delay:opt.interval * 2,
      ease:opt.ease
    });
    TweenMax.to(this._pants.position, opt.duration, {
      x:this._pants.position.x + opt.dx,
      delay:opt.interval,
      ease: opt.ease
    })
    this._pants.fadeOut({
      duration:opt.duration,
      delay:opt.interval * 2,
      ease:opt.ease
    });
    this._anm = false;
  }

  startAmin() {
    TweenMax.to(this._pants.position, 1.5, {
      y:this._pants.position.y + 92,
      delay: 0.5,
      ease:Power2.ease0ut,
      onComplete: () => {
        setTimeout(() => {
          this._anm =  true;
        },1000);
      }
    })

  }

  update() {

    super.update();

    if(!glParam.isRender[glType.SCENE.ATTENTIONHEM] || !this.visible) {
      return;
    }

    if( this._anm) {
      this._anm = false;
      this.resize()
      this.startAmin()
    }

  }


  resize() {
    this.reset()


    super.resize();
  }

  reset() {
    this._dummy.position.set(0, 210, this._dummyIndex)
    this._pants.position.set(0, 0 , 1)
    this._foot.position.set(0, 0, 0)
  }

}
