// @flow
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import RecommendMenu from './RecommendMenu'
import SearchResultTitle from './searchResult/SearchResultTitle'
import ButtonA from './buttons/ButtonA'
import ProductModal from './ProductModal'
import SelectedItems from './searchResult/SelectedItems'
import RecordInfo from './searchResult/RecordInfo'
import Modal from './Modal'
import { formatSize } from '~/helpers/number'
import RecommendItems from './RecommendItems'
import PushNotification from './PushNotification'
import throttle from 'lodash/throttle'
import NoRecommendItems from './NoRecommendItems'
import {
  RecommendEntity,
  Tags,
  Measurement,
  NonSelectTagItems,
  SelectedTagItems
} from '../types'
import { hot } from 'react-hot-loader'

let scrollTimerId = 0

type Props = {
  items: Array<RecommendEntity>,
  appActions: Object,
  recommendActions: Object,
  tags: Tags,
  measurement: Measurement,
  jis: {
    jisSize: string,
    jisWidth: string
  },
  jisWidthDisplay: boolean,
  nonSelectTagItems: NonSelectTagItems,
  selectedTagItems: SelectedTagItems,
  favoriteItems: Array<RecommendEntity>,
  favorites: Array<number>,
  notificationMessage: ?string,
  itemEntities: { [?number]: RecommendEntity }
}

type State = {
  selectedProductId: ?number,
  scrolling: boolean
}

class Recommend extends Component<Props, State> {
  trottledHandleScroll: () => void

  state = {
    selectedProductId: null,
    scrolling: false
  }

  constructor(props: any) {
    super(props)
    this.trottledHandleScroll = throttle(this.handleScroll, 100)
  }

  isSelected = (itemUuid: number) => {
    return this.props.favorites.includes(itemUuid)
  }

  handleScroll = () => {
    if (scrollTimerId) {
      clearTimeout(scrollTimerId)
    }
    if (!this.state.scrolling) {
      this.setState({ scrolling: true })
    }
    scrollTimerId = setTimeout(() => {
      this.setState({ scrolling: false })
    }, 200)
  }

  handleModalClose = () => {
    this.setState({ selectedProductId: null })
  }

  handleZoom = (itemUuid: number) => () => {
    this.setState({ selectedProductId: itemUuid })
  }

  handleSelect = (itemUuid: number) => () => {
    this.props.recommendActions.onSelect(itemUuid)
  }

  handleDeleteFavorite = (itemUuid: number) => () => {
    this.props.recommendActions.onDeleteFavorite(itemUuid)
  }

  handleTryList = async () => {
    const { recommendActions, appActions, favoriteItems } = this.props
    appActions.showLoading()
    await recommendActions.onRegisterTryList(favoriteItems).catch(() => {
      appActions.hideLoading()
    })
    appActions.hideLoading()
    // 試着リスト画面へ遷移
    appActions.setScene(7)
  }

  handleSelectTag = (selectedTag, selectedLabel) => {
    this.props.recommendActions.onSelectTag(selectedTag, selectedLabel)
    this.setState({ selectedProductId: null })
  }

  filteredItems = (): Array<RecommendEntity> => {
    const { items, selectedTagItems } = this.props
    const selectedTagKeys = Object.keys(selectedTagItems).filter(tag => {
      return selectedTagItems[tag] !== null
    })
    if (selectedTagKeys.length === 0) {
      return items
    }
    return items.filter(item => {
      const ret = []
      for (let i = 0, len = selectedTagKeys.length; i < len; i++) {
        const tag = selectedTagKeys[i]
        const selectedValue = selectedTagItems[tag]
        const itemValue = item[tag]
        ret.push(selectedValue === itemValue)
      }
      return ret.every(r => !!r)
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.trottledHandleScroll, false)
    this.props.appActions.dataLayerPush({ pageID: 'recommend' })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.trottledHandleScroll)
    if (scrollTimerId) {
      clearTimeout(scrollTimerId)
    }
  }

  render() {
    const {
      appActions,
      jisWidthDisplay,
      jis,
      tags,
      nonSelectTagItems,
      selectedTagItems,
      favoriteItems,
      favorites,
      recommendActions,
      notificationMessage,
      itemEntities
    } = this.props
    const filteredItems = this.filteredItems()

    return (
      <Root>
        {filteredItems.length > 0 ? (
          <Fragment>
            <Title />
            <RecommendItems
              favorites={favorites}
              items={filteredItems}
              handleZoom={this.handleZoom}
              handleSelect={this.handleSelect}
              isSelected={this.isSelected}
            />
          </Fragment>
        ) : (
          <NoRecommendItems />
        )}
        <HeadMenu
          tags={tags}
          nonSelectTagItems={nonSelectTagItems}
          selectedTagItems={selectedTagItems}
          handleSelectTag={(selectedTag: string, selectedLabel: string) => {
            this.handleSelectTag(selectedTag, selectedLabel)
          }}
          handleRemoveTag={recommendActions.onRemoveTag}
          favoriteSize={favorites.length}
        />
        <SelectedItemsStyled
          scrolling={this.state.scrolling ? 1 : 0}
          items={favoriteItems}
          empty={favorites.length === 0}
          onDelete={this.handleDeleteFavorite}
          onTry={this.handleTryList}
          favoriteSize={favoriteItems.length}
        />
        <RecordInfoStyled
          scrolling={this.state.scrolling ? 1 : 0}
          onClick={() => {
            // 足カルテへリンク
            appActions.setScene(6)
          }}
          loggedIn={false}
          jis={jis}
          jisWidthDisplay={jisWidthDisplay}
        />
        <TopButton
          onClick={() => {
            appActions.refresh()
          }}
        >
          TOPに戻る
        </TopButton>
        <Notification
          notificationMessage={notificationMessage}
          favoriteSize={favorites.length}
        />
        {this.state.selectedProductId !== null && (
          <Modal
            handleOutsideClick={this.handleModalClose}
            render={() => (
              <ProductModal
                onClose={this.handleModalClose}
                handleSelectTag={(
                  selectedTag: string,
                  selectedLabel: string
                ) => {
                  this.handleSelectTag(selectedTag, selectedLabel)
                }}
                item={itemEntities[this.state.selectedProductId]}
                dataLayerPush={appActions.dataLayerPush}
              />
            )}
          />
        )}
      </Root>
    )
  }
}

export default hot(module)(Recommend)

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
  min-height: 100vh;
  margin-top: 75px;
`

const HeadMenu = styled(RecommendMenu)`
  position: fixed;
  top: 0;
  left: 0;
`

const Title = styled(SearchResultTitle)`
  margin: 110px 0 75px 0;
`

const RecordInfoStyled = styled(RecordInfo)`
  position: fixed;
  top: 100px;
  transition: right 0.3s ease;
  right: ${({ scrolling }) => (scrolling ? '-160px' : 0)};
`

const TopButton = styled(ButtonA)`
  position: fixed;
  bottom: 34px;
  left: 15px;
`

const SelectedItemsStyled = styled(SelectedItems)`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  pointer-events: none;
  transition: bottom 0.3s ease;
  bottom: ${({ scrolling }) => (scrolling ? '-100px' : '25px')};
  margin-left: ${({ favoriteSize }) => (favoriteSize > 6 ? '50px' : '0')};
`

const Notification = styled(PushNotification)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`
