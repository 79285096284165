// @flow
import React from 'react'
import styled from 'styled-components'
import QRCode from 'qrcode.react'

import base from './img/base.png'
import line from './img/ico_line.png'





type Props = {
  value: string,
  clientCode: string
}

function AccountPr({ value, clientCode, ...rest }: Props) {
  return (
    <Root {...rest}>


      <Wrapper>
        <QRCode value={value} size={75} />
      </Wrapper>
      <img src={base} width={220} alt="" />
      <LineText>
      <img src={line} width={25} alt="" />LINEでQRコードを読み込んでください
      </LineText>
    </Root>
  )
}

export default AccountPr

const Root = styled.div`
  width: 220px;
  height: 225px;
  position: relative;
`

const Wrapper = styled.div`
  position: absolute;
  background: #fff;
  padding: 6px;
  border-radius: 8px;
  top: 92px;
  left: 67px;
  > canvas {
    vertical-align: middle;
  }
`
const LineText = styled.p`
  position: absolute;
  top: 180px;
  text-align: center;
  margin: 0 15px;
`
