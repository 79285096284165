// @flow
import React, { Fragment } from 'react'
import styled from 'styled-components'

type Props = {
  error: ?string,
  appActions: Object,
  scene: ?number 
}

interface State {
  isOpen: boolean;
}

function ErrorDialog({ error, appActions, scene }: Props) {
  if (!error) return <Fragment />
  return (
    <Root>
      <Wrapper>
        <dl>
          <dt>
            <i className="fas fa-exclamation-triangle" />
            エラー
          </dt>
          <dd>
            <p dangerouslySetInnerHTML={{ __html: error }} />
            <p>
            { (scene == 3 || scene == 4) &&
              <Button onClick={() => { 
                //this.setState({ isOpen: false })
                appActions.networkError('')
                appActions.setScanStatus(null)
                appActions.setScene(3) }}>
                再計測
               </Button>
            }
              <Button onClick={() => location.reload()}>TOPヘ</Button>
            </p>
          </dd>
        </dl>
      </Wrapper>
    </Root>
  )
}

export default ErrorDialog

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
`

const Wrapper = styled.div`
  width: 520px;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  dl {
    text-align: center;
  }
  dt {
    color: #cc0000;
    font-size: 20px;
    i {
      margin-right: 10px;
    }
  }
  p {
    margin: 20px 0;
  }
`

const Button = styled.button`
  height: 50px;
  min-width: 120px;
  font-weight: bold;
  border-radius: 25px;
  margin: 0 20px;
  background-color: #fff000;
`
