import vert from './shader/base.vert';
import frag from './shader/image.frag';

import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import glConf from './glConf';
import readyDotItem from './readyDotItem';
import readyLine from './readyLine';
import readyOkBtn from './readyOkBtn';
import capture from './capture';
import webgl from './index'


export default class readyDots extends object3d {

  constructor(opt) {

    super(opt)

    this._scene = undefined
    this._dest = undefined

    this._itemContainer = undefined
    this._item = undefined
    this._line = undefined
    this._okBtn = undefined
    this._isMeasure = undefined
    this._isSceneRender = false
    this._text = undefined
    this._text2 = undefined
    this._relax = undefined

    this.onClick = undefined

  }


  init() {

    super.init();

    this._isMeasure = false;

    this._scene = new capture();
    this._scene.init();

    this._dest = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(1, 1),
      new THREE.ShaderMaterial({
        transparent:true,
        vertexShader:vert,
        fragmentShader:frag,
        uniforms:{
          tDiffuse:{value:this._scene.texture()},
          alpha:{value:1},
          bright:{value:0}
        }
      })
    );
    this.add(this._dest);
    // this._dest.position.z = -0.01

    this._itemContainer = new THREE.Object3D();
    this.add(this._itemContainer);
    this._itemContainer.position.z = 1

    this._item = [];
    const num = 8;
    for(let i = 0; i < num; i++) {
      const item = new readyDotItem({
        color:0xffffff
      })
      item.init();
      this._itemContainer.add(item);
      this._item.push(item);
    }

    this._line = new readyLine();
    this._line.init();
    // this.add(this._line);
    this._scene.add(this._line);

    this._text = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'ready_text_mesurement.png'),
      origin:'center'
    })
    this._text.init();
    this._text.setSize(104, 33);
    this._text.visible = false;
    this.add(this._text);

    this._text2= new image({
      tex:glTextures.get(glConf.PATH_IMG + 'ready_text_mesurement_1.png'),
      origin:'center'
    })
    this._text2.init();
    this._text2.setSize(163, 15);
    this._text2.visible = false;
    this.add(this._text2);


    this._relax = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'ready_desc_text_2.png'),
      origin:'center'
    })
    this._relax.init()
    this._relax.setSize(138, 33);
    this.add(this._relax);
    this._relax.alpha(0)

    this._okBtn = new readyOkBtn();
    this._okBtn.init();
    this._scene.add(this._okBtn);

    this.resize();
  }


  show(opt) {
    this._isSceneRender = true

    this.showText();
    opt.onComplete();
/*
    this._line.show({
      onComplete: () => {
        this.showText();
        if(opt.onComplete != null) {
          opt.onComplete()
        }
      }
    })

    this._okBtn.show({
      delay:5,
      onComplete: () => {
        if(opt.onComplete != null) {
          opt.onComplete()
        }
      }
    });
    */

  }

  showText() {
    this._text.visible = true;
    this._text.alpha(1)
    this._text.fadeIn({
      duration:1.5,
      delay:0
    });

    this._text2.visible = true;
    this._text2.alpha(1)
    this._text2.fadeIn({
      duration:1.5,
      delay:0
    });
  }


  hideText() {
    this._text.fadeOut({
      duration:0.5,
      delay:0,
      onComplete: () => {
        // 最後のメッセージ出してすぐ消す
        this._relax.zoomIn({
          scale:1.5,
          duration:1,
          delay:1,
          ease:Elastic.easeOut.config(1, 0.8),
          onComplete:() => {
            this._relax.zoomOut({
            scale:0.5,
            duration:1,
            delay:0.5,
            ease:Power4.easeInOut
            })
          }
        })
      }
    });
    this._text2.fadeOut({
      duration:0.5,
      delay:0,
      onComplete: () => {
      }
    });
  }

  restart(opt) {
    this._relax.alpha(0);
    this._itemContainer.position.z = 1
    this._itemContainer.rotation.z = 0
    for(let i = 0; i < this._item.length; i++) {
      this._item[i]._isShow = false
      this._item[i]._isChange = false
      this._item[i].visible = true
      this._item[i]._dot.visible = false;
      this._item[i]._ring.visible = false;
      this._item[i].angle = 0;
      this._item[i].resize()
    }
    this.resize()

    this._line._dot.scale.set(22, 22, 1);
    this._line.visible = false;
    this._line.anm = {
      angle:{value:-1},
      radius:{value:1}
    }
    this._line.resize()

    this._isMeasure = false
    this._isSceneRender = true
    this._dest.visible = true

    this._line.show({})
    this._okBtn.show({
      delay:2,
      onComplete: () => {
        if(opt.onComplete != null) {
          opt.onComplete()
        }
      }
    });

  }


  hide(opt) {
    this._okBtn.hide({
      delay:opt.delay,
      onComplete: () => {
        this._dest.visible = false
        this._isSceneRender = false
      }
    });

  }


  startMeasure(opt) {
    this._isMeasure = true;
  }

  stopMeasure(opt) {
    this._isMeasure = false;
  }

  startComplete(opt) {
    this.hideText()

    for(let i = 0; i < this._item.length; i++) {
      this._item[i].startComplete();
    }

    const s = 1.5;
    TweenMax.to(this.scale, 0.5, {
      x:s,
      y:s,
      ease:Back.easeIn,
      delay:opt.delay,
      onComplete: () => {
        TweenMax.to(this.scale, 1, {
          x:1,
          y:1,
          ease:Elastic.easeOut.config(1, 0.8),
          onComplete:() => {
          }
        });
      }
    })

  }

  startNext(opt) {

    TweenMax.to(this._itemContainer.rotation, 3, {
      z:glUtils.radian(360),
      delay:opt.delay,
      ease:Power4.easeInOut
    });
    
    for(let i = 0; i < this._item.length; i++) {
      this._item[i].startNext({
        delay:opt.delay + i * 0.1,
        onComplete:(i == this._item.length - 1 ? () => {
          if(opt.onComplete != null) {
            opt.onComplete()
          }
        } : null)
      });
    }

  }


  update() {

    super.update();

    const len = this._item.length;

    for(let i = 0; i < len; i++) {

      const item = this._item[i];

      if(this._isMeasure && this.opt.radar.visible && !this.opt.radar.isHide) {
        item.change({
          nowAngle:this.opt.radar.getNowAngle()
        })
      } else {
        item.show({
          nowAngle:this._line.getNowAngle()
        });
      }

    }

    if(this._isSceneRender) {
      webgl.renderer.setClearColor(glConf.KEY_COLOR, 1);
      this._scene.render(webgl.renderer, webgl.camera);
    }

  }

  resize() {

    super.resize();

    const radius = this.sw * glConf.CMN_RADIUS;
    for(let i = 0; i < this._item.length; i++) {
      const item = this._item[i];
      item.angle = i * (360 / this._item.length);
      const radian = glUtils.radian(item.angle);
      item.position.x = Math.sin(radian) * radius;
      item.position.y = Math.cos(radian) * radius;
    }

    this._okBtn.position.set(this.sw * 0.5 - 150, -this.sh * 0.5 + 150, 0);

    this._scene.size(this.sw, this.sh);
    this._dest.scale.set(this.sw, this.sh, 1);
    //this._relax.setSize(this.sw, this.sw);
    this._text2.position.set( 0, -80, 0);


  }

}
