// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import ArrowButton from './iconButtons/Arrow'
import Button from './buttons/ButtonA'
import QRCode from './QRcode'
import Product from './tryList/Product'
import logoPng from '../assets/img/flicfit-logo.png'
import titlePng from './img/try-list/title.png'
import n1 from './img/try-list/n1.png'
import n2 from './img/try-list/n2.png'
import illust from './img/try-list/illust.png'
import { hot } from 'react-hot-loader'
import { RecommendEntity } from '../types'

type Props = {
  appActions: Object,
  choicesAndKarteQrCodeUrl: string,
  favoriteItems: Array<RecommendEntity>
}

class TryList extends Component<Props> {
  handleLogout = () => {
    this.props.appActions.refresh()
  }

  componentDidMount() {
    this.props.appActions.dataLayerPush({ pageID: 'trylist' })
  }

  render() {
    const { appActions, choicesAndKarteQrCodeUrl, favoriteItems } = this.props

    return (
      <Root>
        <Top>
          <Title>
            <img
              src={titlePng}
              alt="試着をご希望の商品"
              width="266"
              height="28"
            />
          </Title>
          <Products>
            {favoriteItems.map(item => {
              return (
                <li key={item.itemUuid}>
                  <Product
                    data={{
                      maker: item.brandName,
                      productNumber: item.productNumber,
                      name: item.productName,
                      size: `${item.makerSize}${item.unit}`,
                      width: `${item.makerWidth}`,
                      color: item.makerColor,
                      material: item.makerMaterial,
                      price: item.price
                    }}
                    label={item.fit}
                    image={item.pictureUri[0]}
                  />
                </li>
              )
            })}
          </Products>
        </Top>
        <Bottom>
          <BottomWrapper>
            <ol>
              <li>
                <i>
                  <img src={n1} width="22" height="22" alt="" />
                </i>
                <span>スマートフォンでQRコードを読み取る</span>
              </li>
              <li>
                <i>
                  <img src={n2} width="22" height="22" alt="" />
                </i>
                <span>ショップスタッフにリストを見せて靴を試着しよう</span>
              </li>
            </ol>
            <QRCode value={choicesAndKarteQrCodeUrl} />
          </BottomWrapper>
          <Illust src={illust} width={151} height={162} />
        </Bottom>
        <Logo src={logoPng} width="117" heigth="31" alt="FlickFit" />
        <BackButton
          dir="previous"
          onClick={() => {
            // Recommendへ遷移
            appActions.setScene(5)
          }}
        />
        <TopButton onClick={this.handleLogout}>TOPに戻る</TopButton>
      </Root>
    )
  }
}

export default hot(module)(TryList)

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f3f3f3;
  width: 100vw;
  height: 100vh;
`

const Logo = styled.img`
  position: fixed;
  top: 25px;
  right: 25px;
`

const BackButton = styled(ArrowButton)`
  position: fixed;
  top: 15px;
  left: 15px;
`

const TopButton = styled(Button)`
  position: fixed;
  bottom: 37px;
  left: 15px;
`

const Top = styled.div`
  height: 422px;
`

const Title = styled.div`
  padding: 80px 0 20px 0;
  text-align: center;
`

const Bottom = styled.div`
  height: calc(100vh - 422px);
  background-color: ${({ theme }) => theme.color.yellow};
  position: relative;
`

const BottomWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  ol {
    font-size: 20px;
    letter-spacing: 1px;
  }
  img {
    vertical-align: middle;
    position: relative;
    top: -3px;
  }
  i {
    margin-right: 12px;
  }
  li {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
`

const Illust = styled.img`
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-left: -258px;
`

const Products = styled.ul`
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  padding: 0 50px;
  margin-left: -22px;
  &:after {
    content: ' ';
    min-width: 5px;
  }
  li {
    margin: 0 22px;
    /* flex: 1 1 auto; */
  }
`
