// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import RefreshButton from './RefreshButton'
import Thumbnail from './thumbnail/SelectThumbnail'

type Props = {
  refresh: () => void,
  dataLayerPush: (params: { pageID: string }) => void,
  recommendActions: Object,
  precondition: Object
}

export default class SelectColorAndHeel extends Component<Props> {
  componentDidMount() {
    console.log("SelectColorAndHeel")
    this.props.dataLayerPush({ pageID: 'SelectColorAndHeel' })
  }

  isSelected = (id: string) => {
    return this.props.precondition.ffColorId.includes(id)
  }

  handleSelect = (id: string) => () => {
    const  { ffColorId } = this.props.precondition
    let tmpColor = [...ffColorId]
    if (ffColorId.includes(id)) {
      tmpColor = ffColorId.filter(fid => fid !== id)
    } else {
      tmpColor.push(id)
    }
    this.props.recommendActions.onSelectPreconditionColor(tmpColor)
  }

  isHeelSelected = (id: string) => {
    return this.props.precondition.heel.includes(id)
  }

  handleHeelSelect = (id: string) => () => {
    const  { heel } = this.props.precondition
    //let tmpHeel = [...heel]
    let tmpHeel = []
    if (heel.includes(id)) {
      tmpHeel= heel.filter(fid => fid !== id)
    } else {
      tmpHeel.push(id)
    }
    this.props.recommendActions.onSelectPreconditionHeel(tmpHeel)
  }

  render() {
    const { refresh } = this.props

    const colorItems = [
      { id: "cool", name : "寒色系" , pic: "/img/colors/11.png"},
      { id: "warm", name : "暖色系" , pic: "/img/colors/14.png"},
      { id: "monotone", name : "白・黒", pic: "/img/colors/1.png"},
      { id: "other", name : "その他", pic: "/img/colors/17.png"},
    ]
    let count = 0

    const heelItems = [
      { id: "flat", name : "フラット" , pic: "/img/webgl/dummy/shoes_15.jpg"},
      { id: "low", name : "低" , pic: "/img/webgl/dummy/shoes_12.jpg"},
      { id: "middle", name : "中" , pic: "/img/webgl/dummy/shoes_8.jpg"},
      { id: "height", name : "高", pic: "/img/webgl/dummy/shoes_3.jpg"},
    ]

    return  ( 
      <Root>
      <RefreshButton onClick={refresh} />

      <Category id="colorlist">
        {colorItems.map(item => {
          count++
          let classNameStr = count % 2 === 0 ? 'large' : 'small'
          classNameStr += ' '
          //classNameStr += count % 3 === 2 ? 'center' : ''
          classNameStr += 'center'
          return (
            <li key={item.id} className={classNameStr}>
            { item.id && 
              <Thumbnail
                type='large'
                name={item.name}
                src={item.pic}
                selected={this.isSelected(item.id)}
                onSelect={this.handleSelect(item.id)}
              />
            }
            </li>
          )
        })}
      </Category>

      <Heel id="heelheightlist">
        {heelItems.map(item => {
          let classNameStr = 'center'
          return (
            <li key={item.id} className={classNameStr}>
            { item.id && 
              <Thumbnail
                type='large'
                name={item.name}
                src={item.pic}
                selected={this.isHeelSelected(item.id)}
                onSelect={this.handleHeelSelect(item.id)}
              />
            }
            </li>
          )
        })}
      </Heel>
      </Root>
    )
  }
}



const Root = styled.div`
`
const Category = styled.ul`
  opacity: 0;
  width: 955px;
  margin: 90px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 180px;
  > li {
    margin-right: auto;
    margin-left: auto;
    &.center {
      margin-top: auto;
    }
    &.small.center {
      margin-bottom: 20px;
    }
  }
`

const Heel = styled.ul`
  opacity: 0;
  width: 470px;
  margin-top: 50px;
  margin-left: 35px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 180px;
  > li {
    margin-right: auto;
    margin-left: auto;
    &.center {
      margin-top: auto;
    }
    &.small.center {
      margin-bottom: 20px;
    }
  }
`
