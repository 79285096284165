// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as appActions } from '../redux/modules/app'
import Karte from '../components/Karte'

const mapStateToProps = state => ({
  introAnimationed: state.app.introAnimationed,
  plyFileUrl: state.webgl.plyFileUrl,
  choicesAndKarteQrCodeUrl: state.app.choicesAndKarteQrCodeUrl,
  left: state.app.measurement.left,
  right: state.app.measurement.right,
  jis: state.app.jis,
  heelWidthJa: state.app.heelWidthJa,
  instepHeightJa: state.app.instepHeightJa,
  jisWidthDisplay: state.app.jisWidthDisplay,
  karteDataLevel: state.app.karteDataLevel,
  footUuid: state.app.footUuid,
  clientCode: state.app.clientCode
})

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Karte)
