// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import RefreshButton from './RefreshButton'
import Thumbnail from './thumbnail/SelectThumbnail'

type Props = {
  refresh: () => void,
  dataLayerPush: (params: { pageID: string }) => void, 
  recommendActions: Object,
  precondition: Object,
  target: string
}

export default class SelectCategory extends Component<Props> {
  componentDidMount() {
    this.props.dataLayerPush({ pageID: 'SelectCategory' })
    console.log('selectCategory')
    console.log(this.props.precondition)
  }

  isSelected = (name: string) => {
    return this.props.precondition.category.includes(name)
  }

  handleSelect = (name: string) => () => {
    const  { category } = this.props.precondition
    let tmpCategory = [...category]
    if (category.includes(name)) {
      tmpCategory = category.filter(cname => cname !== name)
    } else {
      tmpCategory.push(name)
    }
    this.props.recommendActions.onSelectPreconditionCategory(tmpCategory)
  }

  render() {
    const { refresh, target } = this.props
    let items = [] 
    let userTarget = [...target]
    if (target.length == 0 ) userTarget = ["womenFormal"]

    userTarget.forEach(element => {
      switch (element) {
        case "menCasual":
          items.push({ id: "スニーカー", name : "スニーカー" , pic: "/img/webgl/dummy/shoes_10.jpg"})
          items.push({ id: "サンダル", name : "サンダル", pic: "/img/webgl/dummy/shoes_7.jpg"})
          items.push({ id: "スリッポン", name : "スリッポン" , pic: "/img/webgl/dummy/shoes_15.jpg"})
          items.push({ id: "モカシン・デッキシューズ", name : "モカシン・デッキシューズ", pic: "/img/webgl/dummy/shoes_8.jpg"})
          items.push({ id: "レインシューズ", name : "レインシューズ" , pic: "/img/webgl/dummy/shoes_9.jpg"})
          break;
        case "menFormal":
          items.push({ id: "その他シューズ", name : "その他シューズ", pic: "/img/webgl/dummy/shoes_11.jpg"})
          break;
        case "womenCasual":
          items.push({ id: "スニーカー", name : "スニーカー" , pic: "/img/webgl/dummy/shoes_10.jpg"})
          items.push({ id: "サンダル", name : "サンダル", pic: "/img/webgl/dummy/shoes_7.jpg"})
          items.push({ id: "スリッポン", name : "スリッポン" , pic: "/img/webgl/dummy/shoes_15.jpg"})
          items.push({ id: "モカシン・デッキシューズ", name : "モカシン・デッキシューズ", pic: "/img/webgl/dummy/shoes_8.jpg"})
          items.push({ id: "レインシューズ", name : "レインシューズ" , pic: "/img/webgl/dummy/shoes_9.jpg"})
          break;
        case "womenFormal":
          items.push({ id: "パンプス", name : "パンプス" , pic: "/img/webgl/dummy/shoes_1.jpg"})
          items.push({ id: "ドレスシューズ", name : "ドレスシューズ", pic: "/img/webgl/dummy/shoes_2.jpg"})
          items.push({ id: "バレエシューズ", name : "バレエシューズ", pic: "/img/webgl/dummy/shoes_3.jpg"})
          items.push({ id: "ブーティ", name : "ブーティ", pic: "/img/webgl/dummy/shoes_4.jpg"})
          items.push({ id: "ブーツ", name : "ブーツ" , pic: "/img/webgl/dummy/shoes_5.jpg"})
          break;
        case "kidsCasual":
          break;
        case "kidsFormal":
          break;
        default:
          break;
      }
    });
    items = items.filter((x, i, self) => self.indexOf(x) === i);
    console.log("ITEMS __> ", items)


    let count = 0

    return  ( 
      <Root>
      <RefreshButton onClick={refresh} />

      <Category id="categorylist">
        {items.map(item => {
          count++
          let classNameStr = count % 2 === 0 ? 'large' : 'small'
          classNameStr += ' '
          //classNameStr += count % 3 === 2 ? 'center' : ''
          classNameStr += 'center'
          return (
            <li key={item.id} className={classNameStr}>
            { item.id && 
              <Thumbnail
                name={item.name}
                src={item.pic}
                selected={this.isSelected(item.name)}
                onSelect={this.handleSelect(item.name)}
              />
            }
            </li>
          )
        })}
      </Category>
      </Root>
    )


  }
}

const Root = styled.div`
`
const Category = styled.ul`
  opacity: 0;
  width: 955px;
  margin: 180px auto;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: 180px;
  > li {
    margin-right: auto;
    margin-left: auto;
    &.center {
      margin-top: auto;
    }
    &.small.center {
      margin-bottom: 20px;
    }
  }
`