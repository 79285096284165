// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import ArrowButton from './iconButtons/Arrow'
import Button from './buttons/ButtonA'
import Label from './label/LabelA'
import AccountPr from './AccountPr'
import KarteTitle from './karte/Title'
import KarteButton from './karte/Button'
import KarteData from './karte/DataList'
import RecommendButton from './karte/RecommendButton'
import FootTypeModal from './FootTypeModal'
import LogoPng from '~/assets/img/flicfit-logo.png'
import Modal from './Modal'
import KarteCanvas from './KarteCanvas'
import webgl from '~/webgl'
import { formatSize } from '~/helpers/number'
import { FootMeasurement } from '../types/'
import textPng from './karte/img/text.png'
import illustLeftPng from './karte/img/illustLeft.png'
import illustRightPng from './karte/img/illustRight.png'
import lineIconPng from './karte/img/ico_line.png'

type Props = {
  appActions: Object,
  plyFileUrl: string,
  choicesAndKarteQrCodeUrl: string,
  left: FootMeasurement,
  right: FootMeasurement,
  jis: {
    jisSize: string,
    jisWidth: string
  },
  heelWidthJa: Object, 
  instepHeightJa: Object,
  jisWidthDisplay: boolean,
  karteDataLevel: string,
  introAnimationed: boolean,
  footUuid: string,
  clientCode: string,
}

type State = {
  modal: boolean
}

class Karte extends Component<Props, State> {
  state = {
    modal: false
  }

  handleLogout = () => {
    this.props.appActions.refresh()
  }

  handleToggleModal = (bool: boolean) => {
    this.setState({ modal: bool })
  }
  componentDidMount() {
    // webglの表示
    if (this.props.introAnimationed) {
      webgl.showShoes({
        el: document.getElementById('canvas-karte'),
        plyFileUrl: this.props.plyFileUrl
      })
    }
    this.props.appActions.dataLayerPush({ pageID: 'karte' })
  }

  componentWillUnmount() {
    // webglを消去
    webgl.hideShoes()
  }

  componentDidUpdate() {
    if (this.props.introAnimationed) {
      webgl.showShoes({
        el: document.getElementById('canvas-karte'),
        plyFileUrl: this.props.plyFileUrl
      })
    }
  }

  render() {
    const {
      appActions,
      choicesAndKarteQrCodeUrl,
      left,
      right,
      jis,
      instepHeightJa,
      heelWidthJa,
      jisWidthDisplay,
      karteDataLevel,
      introAnimationed,
      footUuid,
      clientCode
    } = this.props

    console.log("AAAVBBDDEerr", clientCode)

    return (
      <Root>
        <KarteCanvas />
        <div style={{ position: 'relative' }}>
          <QRCode value={choicesAndKarteQrCodeUrl} clientCode={clientCode} />
          <Logo src={LogoPng} width={117} />
          <Title jis={jis} jisWidthDisplay={jisWidthDisplay} />
          <ModalButton type={0} onClick={() => this.handleToggleModal(true)} />
          <KarteDataBlock 
            left={left} 
            right={right} 
            karteDataLevel={karteDataLevel} 
            heelWidthJa={heelWidthJa}
            instepHeightJa={instepHeightJa}
            />
          <FootUuid>{footUuid}</FootUuid>
          <TopButton onClick={this.handleLogout}>TOPに戻る</TopButton>
          {this.state.modal && (
            <Modal
              handleOutsideClick={() => this.handleToggleModal(false)}
              render={() => (
                <FootTypeModal
                  handleClose={() => this.handleToggleModal(false)}
                  type={right.footMeasurement.toeShapeType.replace('ToeShapeType.', '')}
                  dataLayerPush={appActions.dataLayerPush}
                />
              )}
            />
          )}
        </div>
      </Root>
    )
  }
}

export default Karte

const Root = styled.div`
  background-color: #f3f3f3;
  height: 100vh;
`

const QRCode = styled(AccountPr)`
  position: fixed;
  right: 0;
  top: 100px;
  pointer-events: none;
`

const BackButton = styled(ArrowButton)`
  position: fixed;
  top: 15px;
  left: 15px;
`

const TopButton = styled(Button)`
  position: fixed;
  bottom: 37px;
  left: 15px;
`

const Logo = styled.img`
  position: fixed;
  top: 24px;
  right: 24px;
  pointer-events: none;
`

const Title = styled(KarteTitle)`
  position: fixed;
  top: 78px;
  left: 50%;
  transform: translate(-50%, 0);
  pointer-events: none;
`

const ModalButton = styled(KarteButton)`
  position: fixed;
  top: 205px;
  left: 116px;
`

const KarteDataBlock = styled(KarteData)`
  position: fixed;
  bottom: 30px;
  right: 30px;
`

const OsusumeButton = styled(RecommendButton)`
  position: fixed;
  bottom: 37px;
  left: 378px;
`
const FootUuid = styled.div`
  position: fixed;
  bottom: 0;
  right: 10px;
  color: #999;
`