// @flow
import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import checkIconPng from '~/assets/img/check.png'
import { Transition } from 'react-spring'

type Props = {
  notificationMessage: string,
  favoriteSize: number
}

type State = {
  text: ?string
}

let timerId: ?TimeoutID = null
const timeLimit = 3000

class PushNotification extends Component<Props, State> {
  state = { text: null }

  onRemove = () => {
    this.setState({ text: null })
  }

  onEnter = () => async (next: any) => {
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = setTimeout(this.onRemove, timeLimit)
    next({ opacity: 1 })
  }

  onLeave = () => async (next: any) => {
    if (timerId) {
      clearTimeout(timerId)
    }
    next({ opacity: 0 })
  }

  componentDidUpdate(prevProps: Props) {
    const { notificationMessage, favoriteSize } = this.props
    if (
      !prevProps.notificationMessage &&
      notificationMessage &&
      favoriteSize > prevProps.favoriteSize
    ) {
      this.setState({ text: notificationMessage })
      if (timerId) {
        clearTimeout(timerId)
      }
    }
    if (prevProps.notificationMessage && !notificationMessage) {
      this.setState({ text: null })
      if (timerId) {
        clearTimeout(timerId)
      }
    }
  }

  componentWillUnmount() {
    if (timerId) {
      clearTimeout(timerId)
    }
  }

  render() {
    const { ...rest } = this.props
    return (
      <Transition
        items={this.state.text}
        from={{ opacity: 0 }}
        enter={this.onEnter}
        leave={this.onLeave}
      >
        {text =>
          !!text &&
          (props => (
            <Root style={props} {...rest}>
              <Wrapper>
                {!!this.state.text && (
                  <Fragment>
                    <Icon src={checkIconPng} width={44} height={44} />
                    <Text>{this.state.text}</Text>
                  </Fragment>
                )}
              </Wrapper>
            </Root>
          ))
        }
      </Transition>
    )
  }
}

const Root = styled.div`
  width: 380px;
  height: 125px;
  border-radius: 20px;
  border: 2px solid #e4e4e4;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Icon = styled.img`
  margin: 0 10px;
`

const Text = styled.div`
  font-size: 20px;
`

export default PushNotification
