// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.Node
}

const LabelA: React$StatelessFunctionalComponent<Props> = React.memo(
  ({ children, ...rest }) => <LabelStyle {...rest}><p>{children}</p></LabelStyle>
)

export default LabelA

const LabelStyle = styled.div`
  position: relative;
  width: 124px;
  font-size: 13px;
  font-family: ${({ theme }) => theme.font.bold};
  height: 50px;
  background: ${({ theme }) => theme.color.yellow};
  padding: 0 27px;
  border-radius: 25px;
  color: #000;
  &:disabled {
    color: ${({ theme }) => theme.color.grey};
  }
  p {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
  }
`
