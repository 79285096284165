// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import Description from './modal/Description'
import IconClose from './iconButtons/Close'
import Slider from 'react-slick'
import ArrowButton from './buttons/ButtonF'
import Thumbnail from './modal/Thumbnail'
import Categories from './modal/Categories'
import SliderItem from './modal/SliderItem'
import { RecommendEntity } from '../types'

// $FlowFixMe
const { fitTags } = process.env.CONFIG

type Props = {
  item: RecommendEntity,
  onClose: () => void,
  handleSelectTag: (selectedTag: string, selectedLabel: string) => void,
  dataLayerPush: (params: { pageID: string }) => void
}

type State = {
  slickSettings: Object,
  slideIndex: number
}

export default class ProductModal extends Component<Props, State> {
  slider: any

  state = {
    slickSettings: {
      nextArrow: <ArrowButton dir="next" />,
      prevArrow: <ArrowButton dir="prev" />,
      beforeChange: (oldIndex: number, newIndex: number) => {
        this.setState({ slideIndex: newIndex })
      }
    },
    slideIndex: 0
  }

  componentDidMount() {
    this.props.dataLayerPush({
      pageID: `modal-detail/${this.props.item.itemUuid}`
    })
  }

  render() {
    const { item, onClose, handleSelectTag } = this.props
    const { pictureUri } = item

    return (
      <Root>
        <CloseButton onClick={onClose} />
        <StyledDescription item={item} />
        <StyeldCategories
          items={[
            { label: item.targetName, tag: 'targetName', key: item.targetName },
            {
              label: item.categoryName,
              tag: 'categoryName',
              key: item.categoryName
            },
            { label: fitTags[item.fit], tag: 'fit', key: item.fit },
            {
              label: item.strap ? 'ストラップ' : '',
              tag: 'strap',
              key: item.strap
            },
            {
              label: item.shoelace ? '靴紐' : '',
              tag: 'shoelace',
              key: item.shoelace
            },
            { colorId: item.ffColorId, tag: 'ffColorId', key: item.ffColorId }
          ]}
          handleSelectTag={handleSelectTag}
        />
        <CarouselWrapper>
          <Slider
            ref={(slider: any) => (this.slider = slider)}
            {...this.state.slickSettings}
          >
            {pictureUri.map((picture, idx) => (
              <SliderItem key={idx} picture={picture} />
            ))}
          </Slider>
        </CarouselWrapper>
        <Thumbnails>
          <ul>
            {pictureUri.map((picture, idx) => (
              <li
                key={idx}
                onClick={() => {
                  this.slider.slickGoTo(idx)
                }}
              >
                <Thumbnail
                  src={picture}
                  index={idx}
                  slideIndex={this.state.slideIndex}
                />
              </li>
            ))}
          </ul>
        </Thumbnails>
      </Root>
    )
  }
}

const Root = styled.div`
  width: 750px;
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  padding-bottom: 25px;
`

const CloseButton = styled(IconClose)`
  position: absolute;
  top: 12px;
  right: 12px;
`

const StyledDescription = styled(Description)`
  padding: 25px 25px 0 25px;
`

const CarouselWrapper = styled.div`
  width: 612px;
  height: 306px;
  margin: 25px auto;
  position: relative;
  .slick-track {
    display: flex;
  }
  .slick-slider {
    overflow: hidden;
  }
  .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: -22px;
  }
  .slick-prev {
    left: -44px;
  }
  .slick-next {
    right: -44px;
  }
`

const Thumbnails = styled.div`
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-left: 20px;
  ul {
    display: flex;
  }
  li {
    margin: 0 5px;
  }
`

const StyeldCategories = styled(Categories)`
  padding: 0 25px;
  margin-top: 10px;
`
