// @flow
import React, { Component } from 'react'
import RefreshButton from './RefreshButton'

type Props = {
  appActions: Object,
  webglActions: Object,
  recommendActions: Object,
}

type State = {}

export default class Measurement extends Component<Props, State> {
  async componentDidMount() {
    const { appActions, webglActions, recommendActions } = this.props

    const { footUuid } = await appActions.getFootUuid(appActions.digitizerCode)
    await appActions.setFiltering(footUuid)

    appActions.scan(footUuid)
    const [measurement] = await Promise.all([
      appActions.watchMeasurement(footUuid),
    ])
    // 3DデータファイルをwebGL側に渡す
    webglActions.registerPlyFileUrl(measurement.file)

    await appActions.watchAnimationState()
    appActions.dataLayerPush({ pageID: 'measurement' })
  }

  render() {
    const { appActions } = this.props
    return <RefreshButton onClick={appActions.refresh} />
  }
}
