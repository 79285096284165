// @flow

// $FlowFixMe
const { pollingSettings } = process.env.CONFIG

function pollingMeasurement(
  asyncFunc: () => Object,
  dispatchFunc:(status:string) => Object,
  timeout: number = pollingSettings.timeout,
  interval: number = pollingSettings.interval
): Promise<any> {


  const endTime = Date.now() + timeout
  /* DEBUG */
  const startTime = Date.now()

  const checkCondition = async (resolve, reject) => {
    let response = await asyncFunc()
    if ( process.env.NODE_ENV ==  "development"){
      /* DEBUG */
      response = await asyncFunc()
      const diff =  Date.now() - startTime
      // scanStatus: ‘received’, ‘scanned’, ‘3d_uploaded’, ‘processed’, ‘result_uploaded
      if (diff > 0 && diff < 2000) {
        response.data.data.status = 'received'
      } else if ( diff >= 2000 && diff < 4000 ) {
        response.data.data.status = 'scanned'
      } else if ( diff >= 4000 && diff < 5000 ) {
        response.data.data.status = '3d_uploaded'
      } else if ( diff >= 5000 && diff < 7000) {
        response.data.data.status = 'processed'
      } else if ( diff >= 7000 ) {
        response.data.data.status = 'result_uploaded'
      }
    }

    if (response.data.statusCode === 200) {
      if ( response.data.data === void 0 || response.data.data.status === void 0 ) {
        return resolve(response)
      }
      if (response.data.data.status == 'result_uploaded' ) {
        return resolve(response)
      } 

      if (response.data.data.status == 'error' ) {
        dispatchFunc(response.data.data.status )
        return reject(new Error(response.data.data.errorDetail))
      }

      if (response.data.data.status == 'scanned' ) {
        dispatchFunc(response.data.data.status )
      } 
      setTimeout(checkCondition, interval, resolve, reject)
    } else if (response.data.statusCode < 500 && Date.now() < endTime) {
      setTimeout(checkCondition, interval, resolve, reject)
    } else {
      reject(new Error('ネットワークエラーが発生しました。'))
    }
  }
  return new Promise(checkCondition)
}

export default pollingMeasurement