// @flow
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

import app from './modules/app'
import recommend from './modules/recommend'
import webgl from './modules/webgl'
import entities from './modules/entities'

const composeEnhancers = composeWithDevTools({})
const reducer = combineReducers({ app, recommend, webgl, entities })
const middleweare = [thunkMiddleware]

export default (initialState: Object) => {
  const store = createStore<any, any, any>(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleweare))
  )
  return { store }
}
