// @flow
import axios from 'axios'
import { actions as appActions } from './app'
import { normalize, denormalize, schema } from 'normalizr'
import humps from 'humps'
import { cloudApi } from '~/api'
import createTagEntities from '~/helpers/createTagEntities'
import { Tags, SelectedTagItems, RecommendEntity, Precondition } from '../../types'
import pictureUrl from '~/helpers/pictureUrl'

// $FlowFixMe
const { CLOUDV1 } = process.env.CONFIG

// Actions
const REGISTER_TAGS = 'recommend/REGISTER_TAGS'
const REGISTER_RECOMMEND_ENTITIES = 'recommend/REGISTER_RECOMMEND_ENTITIES'
const UPDATE_FAVORITES = 'recommend/UPDATE_FAVORITES'
const SELECT_TAG = 'recommend/SELECT_TAG'
const REMOVE_TAG = 'recoomend/REMOVE_TAG'
const PRECONDITION = 'recoomend/PRECONDITION'
const PREFERENCE = 'recoomend/PREFERENCE'

export const recommendItemsSchema = new schema.Entity(
  'recommendItems',
  {},
  { idAttribute: 'itemUuid' }
)

export const actions = {
  onSelectPreconditionCategory: (category:Array<string>) => (dispatch: any, getState: any) => {
    const {
      recommend: { precondition }
    } = getState()
    let tmpPrecondition = {...precondition}
    tmpPrecondition.category = [...category]
    dispatch({
      type: PRECONDITION,
      payload: { precondition : tmpPrecondition }
    })
  },
  onSelectPreconditionColor: (color:Array<number>) => (dispatch: any, getState: any) => {
    const {
      recommend: { precondition }
    } = getState()
    let tmpPrecondition = {...precondition}
    tmpPrecondition.ffColorId = [...color]
    dispatch({
      type: PRECONDITION,
      payload: { precondition : tmpPrecondition }
    })
  },
  onSelectPreconditionHeel: (heel: Array<number>) => (dispatch: any, getState: any) => {
    const {
      recommend: { precondition }
    } = getState()
    let tmpPrecondition = {...precondition}
    tmpPrecondition.heel = [...heel]
    dispatch({
      type: PRECONDITION,
      payload: { precondition : tmpPrecondition }
    })
  },
  onSelect: (uuid: number) => (dispatch: any, getState: any) => {
    const {
      recommend: { favorites }
    } = getState()
    let tmpFavorites = [...favorites]
    if (favorites.includes(uuid)) {
      tmpFavorites = favorites.filter(fid => fid !== uuid)
    } else {
      tmpFavorites.push(uuid)
    }
    dispatch({
      type: UPDATE_FAVORITES,
      payload: { favorites: tmpFavorites }
    })
  },
  onSelectPreference: ( preference : string) => (dispatch: any, getState: any) => {
    dispatch({
      type: PREFERENCE,
      payload: { preference: preference }
    })
  },
  onDeleteFavorite: (uuid: number) => (dispatch: any, getState: any) => {
    const {
      recommend: { favorites }
    } = getState()
    dispatch({
      type: UPDATE_FAVORITES,
      payload: { favorites: favorites.filter(fid => fid !== uuid) }
    })
  },
  onRegisterTryList: (favoriteItems: Array<RecommendEntity>) => (
    dispatch: any,
    getState: any
  ): Promise<any> => {
    const {
      app: { footUuid }
    } = getState()
    return new Promise(async (resolve, reject) => {
      const response = await cloudApi({
        method: 'post',
        url: `/storeapp/fititems/${footUuid}`,
        data: {
          items: favoriteItems.map(item => {
            return {
              itemUuid: item.itemUuid,
              fit: item.fit,
              type: 'fit'
            }
          })
        }
      })
      if (response.data.statusCode === 200) {
        return resolve({ ok: true })
      } else {
        dispatch(appActions.networkError('試着リストの登録に失敗しました'))
        return reject()
      }
    })
  },
  onSelectTag: (selectedTag: string, selectedLabel: string) => {
    return {
      type: SELECT_TAG,
      payload: { selectedTag, selectedLabel }
    }
  },
  onRemoveTag: (selectedTag: string) => {
    return {
      type: REMOVE_TAG,
      payload: { selectedTag }
    }
  }
}

// Reducer
export type State = {
  tags: Tags,
  availableTags: Tags,
  selectedTags: SelectedTagItems,
  nonSelectTags: SelectedTagItems,
  items: ?Array<number>,
  favorites: Array<number>,
  precondition:  ?Precondition,
  preference: ?string
}

const initialState = {
  tags: [
    'brandName',
    'categoryName',
    'shoeTypeName',
    'targetName',
    'strap',
    'shoelace',
    'fit',
    'heelTypeName',
    'heelHeight',
    'ffColorId',
    'ffMaterial'
  ],
  precondition: {
    category: [],
    heel: [], 
    ffColorId: []
  },
  preference: "normal",
  availableTags: [],
  selectedTags: {
    brandName: null,
    categoryName: null,
    shoeTypeName: null,
    targetName: null,
    strap: null,
    shoelace: null,
    fit: null,
    heelTypeName: null,
    heelHeight: null,
    ffColorId: null,
    ffMaterial: null
  },
  nonSelectTags: {
    brandName: [],
    categoryName: [],
    shoeTypeName: [],
    targetName: [],
    strap: null,
    shoelace: null,
    fit: [],
    heelTypeName: [],
    heelHeight: [],
    ffColorId: [],
    ffMaterial: []
  },
  items: null,
  favorites: []
}

export default function reducer(state: State = initialState, action: any) {
  switch (action.type) {
    case REGISTER_RECOMMEND_ENTITIES: {
      const { result } = action.payload
      return { ...state, items: result }
    }
    case UPDATE_FAVORITES: {
      const { favorites } = action.payload
      return { ...state, favorites }
    }
    case REGISTER_TAGS: {
      const { tagItems, tagList } = action.payload
      return { ...state, nonSelectTags: tagItems, availableTags: tagList }
    }
    case PRECONDITION: {
      const { precondition } = action.payload
      return { 
        ...state, 
        precondition: precondition
      }
    }
    case PREFERENCE: {
      const { preference } = action.payload
      return { 
        ...state, 
        preference: preference
      }
    }
    case SELECT_TAG: {
      const { selectedTag, selectedLabel } = action.payload
      const tmpState = { ...state }
      state.selectedTags[selectedTag] = selectedLabel
      return tmpState
    }
    case REMOVE_TAG: {
      const { selectedTag } = action.payload
      const tmpState = { ...state }
      state.selectedTags[selectedTag] = null
      return tmpState
    }
  }
  return state
}

// misc
export const selectItems = ({
  recommend: { items },
  entities: { recommendItems }
}: Object) => {
  return denormalize(items, [recommendItemsSchema], { recommendItems })
}

export const selecteFavorites = ({
  recommend: { favorites },
  entities: { recommendItems }
}: Object) => {
  return denormalize(favorites, [recommendItemsSchema], { recommendItems })
}
