export default {
  color: {
    white: '#fff',
    yellow: '#fff000',
    grey: '#f3f3f3',
    grey2: '#bfbfbf',
    grey3: '#666666',
    grey4: '#d6d6d6',
    grey5: '#444444',
    grey6: '#dddddd',
    grey7: '#e9e9e9'
  },
  font: {
    regular: 'Hiragino Kaku Gothic ProN',
    bold: 'HiraKakuPro-W6',
    english: 'Helvetica Neue',
    englishBold: 'HelveticaNeue-Bold'
  }
}
