// @flow
import { fdApi, cloudApi } from '~/api'
import humps from 'humps'
import qs from 'qs'
import polling from '~/helpers/polling'
import pollingMeasurement from '~/helpers/pollingMeasurement'
import confirm from '~/factories/confirm'
import { URLJoin } from '~/helpers/string'
import { formatSize } from '~/helpers/number'
import { Measurement } from '../../types'
import formatJisWidth from '~/helpers/formatJisWidth'

// $FlowFixMe
const { USER_APP, filterColor, filterHeel } = process.env.CONFIG

// Actions
const SHOW_LOADING = 'app/showLoading'
const HIDE_LOADING = 'app/hideLoading'
const SET_MEASUREMENT_STATUS = 'app/SET_MEASUREMENT_STATUS'
const REGISTER_MEASUREMENT = 'app/REGISTER_MEASUREMENT'
const INTRO_ANIMATIONED = 'app/INTRO_ANIMATIONED'
const SET_SCENE = 'app/SET_SCENE'
const SET_DIGITIZER_AND_CLIENT_CODE = 'app/SET_DIGITIZER_AND_CLIENT_CODE'
const NETWORK_ERROR = 'app/NETWORK_ERROR'
const SET_FOOT_UUID = 'app/SET_FOOT_UUID'
const SET_ALLOW_REFRESH = 'app/SET_ALLOW_REFRESH'
const GENERATE_3D_DATA_ON_READY = 'app/GENERATE_3D_DATA_ON_READY'
const REGISTER_JIS = 'app/REGISTER_JIS'

type Scene = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 10 | 11 | 12

export type GaParams = {
  pageID: string
}

export const actions = {
  showLoading: () => {
    return { type: SHOW_LOADING }
  },
  hideLoading: () => {
    return { type: HIDE_LOADING }
  },
  getFootUuid: () => (
    dispatch: any,
    getState: any
  ): Promise<void> => {
    const { app: {  digitizerCode}  } = getState()
    return new Promise(async (resolve: any, reject: any) => {

      let data
      if ( process.env.NODE_ENV ==  "development"){
        /* DEBUG */
        data  = humps.camelizeKeys({"status_code":200,"data":{"foot_uuid":"81200270010001-000050-20200828-0006"}})

      } else {
        /* PROD */
        //const { data } = await cloudApi({
        const res = await cloudApi({
          method: 'post',
          url: `/foot/measurement/init`,
          data: {digitizerCode: digitizerCode}
        })
        data = res.data
      }

      if (data.statusCode === 200) {
        const { footUuid } = data.data
        const choicesAndKarteQrCodeUrl = URLJoin(
          USER_APP,
          'choices_and_karte',
          window.btoa(footUuid)
        )
        dispatch({
          type: SET_FOOT_UUID,
          payload: { footUuid, choicesAndKarteQrCodeUrl }
        })
        resolve({ footUuid })
      } else {
        console.error(data)
        //dispatch(actions.networkError(data.message))
        dispatch(actions.networkError('計測準備ができませんでした。<br/>サポートセンターまでご連絡下さい。'))
        reject()
      }
    })
  },


  scan: (footUuid: string) => (  dispatch: any ): Promise<void> => {
    return new Promise(async (resolve: any, reject: any) => {
      /* PROD */
      if ( process.env.NODE_ENV !=  "development"){
        const { data } = await  fdApi({
          method: 'get',
          url: `/api/scan/${footUuid}?mode=both`
        })
      }
      /* DEBUG 　DEBUG用コードなし */


      resolve()
      // エラーハンドリングはpolling側で実施する
      /*
      if (data.statusCode === 200) {
        const { footUuid, time } = data.data
        resolve()
      } else {
        console.error(data)
        //dispatch(actions.networkError(data.message))
        dispatch(actions.networkError(`撮影、データ生成に失敗しました。<br>再撮影してください。<br/><br/>${data.errorDetail}`))
        reject()
      }
      */

    })
  },

  getAnimationState: () => (dispatch: any, getState: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      const {
        app: { generate3dDataOnReady }
      } = getState()
      if (generate3dDataOnReady) {
        return resolve({ ok: true })
      }
      return reject({ ok: false })
    })
  },
  watchAnimationState: () => (dispatch: any, getState: any): Promise<any> => {
    return new Promise(async resolve => {
      await polling(() => {
        return new Promise(resolve => {
          const {
            app: { generate3dDataOnReady }
          } = getState()
          if (generate3dDataOnReady) {
            const response = { data: { statusCode: 200 } }
            return resolve({ ...response })
          }
          const response = { data: { statusCode: 400 } }
          return resolve({ ...response })
        })
      })
      return resolve({ ok: true })
    })
  },
  getJisSize: (footUuid: string) => (  dispatch: any ): Promise<void> => {
    return new Promise(async (resolve: any, reject: any) => {
      const { data } = await  cloudApi({
        method: 'get',
        //url: `/storeapp/feet/${footUuid}/jis`
        url: `/foot/${footUuid}`
      })
      if (data.statusCode === 200) {
        const { foot } = data.data
        let jisSize = foot.left.jisSize
        let jisWidth = foot.left.jisWidth
        let jisWidthJa = foot.left.jisWidthJa
        if ( foot.left.length < foot.right.length ){
          jisSize = foot.right.jisSize
          jisWidth = foot.right.jisWidth
          jisWidthJa = foot.right.jisWidthJa
        }

        const heelWidthJa = {right: foot.right.heelWidthJa, left: foot.left.heelWidthJa}
        const instepHeightJa = {right: foot.right.instepHeightJa, left: foot.left.instepHeightJa}
        
        console.log(":::::::::::::::::::::::::::::::::::;", heelWidthJa, instepHeightJa)


        dispatch({
          type: REGISTER_JIS,
          payload: {
            jis: {
              jisSize: `${formatSize(jisSize)}cm`,
              jisWidth: formatJisWidth(jisWidth),
              jisWidthJa: jisWidthJa
            },
            heelWidthJa,
            instepHeightJa
          }
        })
        resolve()
      } else {
        console.error(data)
        dispatch(actions.networkError('JISデータの取得に失敗しました'))
        reject()
      }
    })
  },
  watchMeasurement: (footUuid: string) => (dispatch: any): Promise<any> => {
    return new Promise(async resolve => {
      try {
        const { data } = await pollingMeasurement(
          () => {
            return cloudApi({
              method: 'get',
              url: `/foot/measurement/status/${footUuid}`
            }).catch(() => {
              throw new Error()
            })
          },
          (status) => {
            return dispatch({
              type: SET_MEASUREMENT_STATUS,
              payload: {
                scanStatus: status
              }
            })
          }
        )

        await dispatch(actions.getJisSize(footUuid))


        const { left, right } = data.data.measValue
        const file = data.data["3dUrl"] 
        const { status } = data.data
        dispatch({
          type: REGISTER_MEASUREMENT,
          payload: {
            measurement: { left, right },
            file: file,
            scanStatus: status
          }
        })
        resolve({ left, right, file: data.data["3dUrl"] })
      } catch (error) {
        console.log(error.message)

        const rsError = error.message.match(/Realsense/);

        if(rsError === null) {
          //dispatch(actions.networkError(`計測情報の取得に失敗しました。<br>再撮影してください。<br/><br/>${error}`))
          dispatch(actions.networkError(`計測情報の取得に失敗しました。<br>再撮影してください。<br/><br/>・スボンの裾は上げてください。<br />・靴下が黒色の場合は撮影できない場合があります。<br/>問い合わせNO：${footUuid}`))
          return
        }
        dispatch(actions.networkError(`カメラのエラーが発生しました<br/><br/>管理ツールからサービスの再起動を実行してください。<br/>問い合わせNO：${footUuid}`))
      }
    })
  },

  setFiltering: (footUuid: string) => (  dispatch: any, getState:any ): Promise<void> => {
    return new Promise(async (resolve: any, reject: any) => {

    const { recommend: { precondition } } = getState()
    console.log("setFiltering")
    console.log(precondition)
    console.log(filterColor)
    console.log(filterHeel)

    let param = { "filtering": { } }
    if (precondition.category.length > 0 ) {
        param.filtering["category"] = precondition.category
    }

		if( precondition.ffColorId.length > 0 ){
      let colors = []
		  precondition.ffColorId.forEach(element => {
        colors = colors.concat(filterColor[`${element}`])
      })
      param.filtering["color"] = colors
    }

		if( precondition.heel.length > 0 ){
      let heel = {}
		  precondition.heel.forEach(element => {
        if (!heel.lower && !heel.upper)  {
          heel['lower'] = filterHeel[`${element}`].lower
          heel['upper'] = filterHeel[`${element}`].upper
          return
        }
        if (heel.lower >  filterHeel[`${element}`].lower ) heel['lower'] = filterHeel[`${element}`].lower
        if (heel.upper != null && filterHeel[`${element}`].upper == null || heel.upper  < filterHeel[`${element}`].upper  ) heel['upper'] = filterHeel[`${element}`].upper
      })
      param.filtering["heel_height_lower"] = heel.lower
      if( heel.upper != null ) param.filtering["heel_height_upper"] = heel.upper
    }
      const { data } = await  cloudApi({
        method: 'put',
        url: `/foot/value/${footUuid}`,
        data: param
      })
      if (data.statusCode === 200) {
        resolve()
      } else {
        console.error(data)
        dispatch(actions.networkError('フィルターデータの登録に失敗しました'))
        reject()
      }
    })
  },



  // シーン変更
  setScene: (scene: Scene) => {
    return {
      type: SET_SCENE,
      payload: { scene }
    }
  },
  // スキャンステータスの変更
  setScanStatus: (status: ?string) => {
    console.log("setStatus")
    console.log(status)
    return {
      type: SET_MEASUREMENT_STATUS,
      payload: { scanStatus: status }
    }
  },
  // イントロアニメーション終了
  introAnimationed: () => {
    return { type: INTRO_ANIMATIONED }
  },
  // デジタイザコードとクライアントコードを登録
  setDigitizerAndClientCode: (data: {
    digitizerCode: string,
    clientCode: string,
    jisWidthDisplay: bool,
    karteDataLevel: string,
    target: string
  }) => {
    const { digitizerCode, clientCode, jisWidthDisplay, karteDataLevel, target } = data
    return {
      type: SET_DIGITIZER_AND_CLIENT_CODE,
      payload: { digitizerCode, clientCode, jisWidthDisplay, karteDataLevel, target }
    }
  },
  // ネットワークエラー
  networkError: (message: string) => {
    return {
      type: NETWORK_ERROR,
      payload: { message }
    }
  },
  setAllowAutoRefresh: (condition: boolean) => {
    return {
      type: SET_ALLOW_REFRESH,
      payload: { condition }
    }
  },
  //自動リロードの実行
  refresh: () => async (dispatch: any, getState: any) => {
    actions.setAllowAutoRefresh(false)
    actions.dataLayerPush({ pageID: 'modal-refresh' })
    const message = refreshConfirmText(getState())
    const isConfirm = await confirm.show(message)
    if (isConfirm) {
      location.reload()
    } else {
      actions.setAllowAutoRefresh(true)
    }
  },
  // 3Dデータ生成の準備完了
  generate3dDataOnReady: () => {
    return { type: GENERATE_3D_DATA_ON_READY }
  },
  // アクセス解析
  dataLayerPush: (params: GaParams) => (dispatch: any, getState: any) => {
    const {
      app: { digitizerCode, clientCode }
    } = getState()
    const sendData = {
      ...params,
      digitizerCode,
      clientCode,
      event: 'loadready'
    }
    console.log('dataLayerPush:', sendData)
    // $FlowFixMe
    dataLayer.push(sendData)
  }
}

// Reducer
export type AppState = {
  scene: Scene,
  introAnimationed: boolean, // イントロアニメーション終了フラグ
  loading: boolean,
  footUuid: ?string,
  measurement: ?Measurement,
  scanStatus: ?string,
  file: ?string,
  clientCode: ?string,
  digitizerCode: ?string,
  target: ?string,
  networkError: string,
  allowAutoRefresh: boolean, // 自動リロードの許可
  generate3dDataOnReady: boolean,
  choicesAndKarteQrCodeUrl: ?string,
  jis: {
    jisSize: string,
    jisWidth: string,
    jisWidthJa: string
  }
}

const initialState = {
  scene: 0,
  introAnimationed: false,
  loading: true,
  footUuid: null,
  measurement: null,
  scanStatus: null,
  file: null,
  clientCode: null,
  digitizerCode: null,
  networkError: '',
  allowAutoRefresh: true,
  generate3dDataOnReady: false,
  choicesAndKarteQrCodeUrl: null,
  jisWidthDisplay: true,
  karteDataLevel: "1",
  target: null,
  jis: {
    jisSize: '',
    jisWidth: '',
    jisWidthJa: ''
  }
}

export default function reducer(
  state: AppState = initialState,
  action: Object
) {
  switch (action.type) {
    case SHOW_LOADING: {
      return { ...state, loading: true }
    }
    case HIDE_LOADING: {
      return { ...state, loading: false }
    }
    case SET_FOOT_UUID: {
      const { footUuid, choicesAndKarteQrCodeUrl } = action.payload
      return { ...state, footUuid, choicesAndKarteQrCodeUrl }
    }
    case SET_MEASUREMENT_STATUS: {
      const { scanStatus } = action.payload
      return { ...state, scanStatus }
    }
    case REGISTER_MEASUREMENT: {
      const { measurement, file, scanStatus } = action.payload
      return { ...state, measurement, file, scanStatus }
    }
    case REGISTER_JIS: {
      const { jis, heelWidthJa, instepHeightJa } = action.payload
        console.log("**heelWidthJa", heelWidthJa)
        console.log("**instepHeightJa", instepHeightJa)
      return { ...state, jis, heelWidthJa, instepHeightJa }
    }
    case INTRO_ANIMATIONED: {
      return { ...state, introAnimationed: true }
    }
    case SET_SCENE: {
      const { scene } = action.payload
      return { ...state, scene }
    }
    case SET_DIGITIZER_AND_CLIENT_CODE: {
      const { digitizerCode, clientCode, jisWidthDisplay, karteDataLevel, target } = action.payload
      return { ...state, digitizerCode, clientCode, jisWidthDisplay, karteDataLevel, target }
    }
    case NETWORK_ERROR: {
      const { message } = action.payload
      return { ...state, networkError: message }
    }
    case SET_ALLOW_REFRESH: {
      const { condition } = action.payload
      return { ...state, allowAutoRefresh: condition }
    }
    case GENERATE_3D_DATA_ON_READY: {
      return { ...state, generate3dDataOnReady: true }
    }
  }
  return state
}

// Helpers

// ホームに戻るConfirm画面のテキスト
export const refreshConfirmText = ({
  app: { measurement }
}: {
  app: { measurement: ?Measurement }
}) => {
  return measurement != null
    ? 'TOPに戻ります。<br/>撮影データは保存されません。'
    : 'TOPに戻ります。'
}
