// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import selectPreference from '~/components/selectPreference'
import { actions } from '~/redux/modules/app'
import { actions as recommendActions } from '~/redux/modules/recommend'

const mapStateToProps = state => ({
  precondition: state.recommend.precondition,
  preference: state.recommend.preference
})

const mapDispatchToProps = dispatch => ({
  refresh: () => dispatch(actions.refresh()),
  dataLayerPush: params => dispatch(actions.dataLayerPush(params)),
  recommendActions: bindActionCreators({ ...recommendActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(selectPreference)
