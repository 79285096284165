// @flow
import React, { Fragment, PureComponent } from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'
import { Transition } from 'react-spring'
import ZoomIcon from '~/components/iconButtons/Zoom'
import { addComma } from '~/helpers/number'
import labelFitPng from '~/assets/img/label-good.png'
import labelLoosePng from '~/assets/img/label-yuttari.png'
import labelTightPng from '~/assets/img/label-shikkari.png'
import checkPng from '~/assets/img/check.png'
import { LabelTypes } from '~/types'
import * as easings from 'd3-ease'
import ThumbnailLoading from '~/components/ThumbnailLoading'

const labels = {
  fit: labelFitPng,
  loose: labelLoosePng,
  tight: labelTightPng
}

type Props = {
  // 0:small, 1:large
  type: 0 | 1,
  selected?: boolean,
  fit?: LabelTypes,
  src: string,
  price: number,
  onSelect: () => void,
  onZoom: () => void
}

type State = {
  loaded: boolean
}

class MainThumbnail extends PureComponent<Props, State> {
  state = {
    loaded: false
  }

  render() {
    const {
      type,
      selected,
      fit,
      src,
      price,
      onSelect,
      onZoom,
      ...rest
    } = this.props

    return (
      <Root type={type} selected={selected} fit={fit} {...rest}>
        <ImgWrapper>
          <ThumbnailLoading loaded={this.state.loaded} />
          <LazyLoad once height={type === 0 ? 250 : 300}>
            <Thumbnail
              type={type}
              src={src}
              alt=""
              onClick={onSelect}
              onLoad={() => {
                this.setState({ loaded: true })
              }}
            />
          </LazyLoad>
        </ImgWrapper>
        <Fragment>
          <Transition
            from={{ opacity: 1, transform: 'scale(0.9)' }}
            enter={{ opacity: 1, transform: 'scale(1)' }}
            leave={{ opacity: 0, transform: 'scale(0.9)' }}
            items={selected}
            config={{
              duration: 200,
              easing: easings.easeBackOut
            }}
          >
            {seleced => seleced && (props => <Border style={props} />)}
          </Transition>
          <Transition
            from={{ transform: 'scale(0)' }}
            enter={{ transform: 'scale(1)' }}
            leave={{ transform: 'scale(0)' }}
            items={selected}
            config={{
              duration: 200,
              easing: easings.easeBackOut,
              delay: 150
            }}
          >
            {seleced =>
              seleced &&
              (props => (
                <CheckIcon
                  style={props}
                  src={checkPng}
                  width={44}
                  height={44}
                />
              ))
            }
          </Transition>
        </Fragment>
        <Price>
          <span>{addComma(price)}</span>
        </Price>
        <ZoomIconWrapper>
          <ZoomIcon onClick={onZoom} />
        </ZoomIconWrapper>
        {fit != null && fit != "outside" && (
          <Label type={type}>
            <img src={labels[fit]} width={107} height={29} />
          </Label>
        )}
      </Root>
    )
  }
}

export default MainThumbnail

function thumbnailSize(type) {
  return type === 0 ? 250 : 300
}

function zoomPosition(type) {
  return type === 0 ? 10 : 20
}

const Root = styled.div`
  width: ${({ type }) => `${thumbnailSize(type)}px`};
  height: ${({ type }) => `${thumbnailSize(type)}px`};
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Border = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: ${({ theme }) => `3px solid ${theme.color.yellow}`};
  border-radius: 50%;
  pointer-events: none;
`

const CheckIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -22px 0 0 -22px;
  pointer-events: none;
`

const ImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
`

const Thumbnail = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`

const Price = styled.div`
  position: absolute;
  text-align: center;
  bottom: 20px;
  font-size: 17px;
  color: ${({ theme }) => theme.color.grey5};
  line-height: 25px;
  padding: 0 15px;
  background-color: #fff;
  border-radius: 24px;
  pointer-events: none;
  > span {
    font-family: ${({ theme }) => theme.font.english};
    &:before {
      content: '¥';
    }
  }
  &:after {
    content: '(税抜)';
    font-size: 13px;
    margin-left: 4px;
  }
`

const ZoomIconWrapper = styled.div`
  position: absolute;
  right: ${({ type }) => `${zoomPosition(type)}px`};
  bottom: ${({ type }) => `${zoomPosition(type)}px`};
`

const Label = styled.div`
  position: absolute;
  top: ${({ type }) => (type === 0 ? '14px' : '24px')};
  left: ${({ type }) => (type === 0 ? '10px' : '20px')};
  pointer-events: none;
`
