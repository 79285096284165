// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import Thumbnail from './thumbnail/MainThumbnail'
import { RecommendEntity } from '../types'

type Props = {
  items: Array<RecommendEntity>,
  handleZoom: (uuid: number) => () => void,
  handleSelect: (uuid: number) => () => void,
  isSelected: (uuid: number) => boolean,
  favorites: Array<number>
}

class RecommendItems extends Component<Props, {}> {
  shouldComponentUpdate(nextProps: any) {
    return (
      nextProps.favorites.length !== this.props.favorites.length ||
      nextProps.items.length !== this.props.items.length
    )
  }

  render() {
    const { items, handleZoom, handleSelect, isSelected } = this.props
    let count = 0

    return (
      <Root>
        {items.map(item => {
          count++
          let classNameStr = count % 2 === 0 ? 'large' : 'small'
          classNameStr += ' '
          classNameStr += count % 3 === 2 ? 'center' : ''
          return (
            <li key={item.itemUuid} className={classNameStr}>
              <Thumbnail
                src={item.pictureUri[0]}
                fit={item.fit}
                type={count % 2 === 0 ? 1 : 0}
                price={item.price}
                selected={isSelected(item.itemUuid)}
                onZoom={handleZoom(item.itemUuid)}
                onSelect={handleSelect(item.itemUuid)}
              />
              <Infos>
                <p>{item.brandName}</p>
                <p>{item.productNumber}</p>
                <p>
                  {item.makerSize}
                  {item.unit}
                </p>
              </Infos>
            </li>
          )
        })}
      </Root>
    )
  }
}

export default RecommendItems

const Root = styled.ul`
  width: 955px;
  margin: 0 auto;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  grid-auto-rows: 420px;
  > li {
    margin-right: auto;
    margin-left: auto;
    &.center {
      margin-top: auto;
    }
    &.small.center {
      margin-bottom: 20px;
    }
  }
`

const Infos = styled.div`
  font-size: 13px;
  color: #aaaaaa;
  text-align: center;
  margin-top: 10px;
`
