// @flow
import React, { Fragment, PureComponent } from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'
import { Transition } from 'react-spring'
import ZoomIcon from '~/components/iconButtons/Zoom'
import { addComma } from '~/helpers/number'
import labelFitPng from '~/assets/img/label-good.png'
import labelLoosePng from '~/assets/img/label-yuttari.png'
import labelTightPng from '~/assets/img/label-shikkari.png'
import checkPng from '~/assets/img/check.png'
import { LabelTypes } from '~/types'
import * as easings from 'd3-ease'
import ThumbnailLoading from '~/components/ThumbnailLoading'

type Props = {
  selected?: boolean,
  name : string,
  src: string,
  type?: string,
  onSelect: () => void,
}

type State = {
  loaded: boolean
}

class SelectThumbnail extends PureComponent<Props, State> {
  state = {
    loaded: false
  }

  render() {
    const {
      name,
      selected,
      src,
      onSelect,
      type,
      ...rest
    } = this.props
    console.log("SELECT TYPE __> ", type)

    return (
      <Root selected={selected} {...rest}>
        <ImgWrapper>
          <ThumbnailLoading loaded={this.state.loaded} />
          <LazyLoad once height={250}>
            <Thumbnail
              src={src}
              type={type}
              alt=""
              onClick={onSelect}
              onLoad={() => {
                this.setState({ loaded: true })
              }}
            />
          </LazyLoad>
        </ImgWrapper>
        <Label>
          <span>{name}</span>
        </Label>
        <Fragment>
          <Transition
            from={{ opacity: 1, transform: 'scale(0.9)' }}
            enter={{ opacity: 1, transform: 'scale(1)' }}
            leave={{ opacity: 0, transform: 'scale(0.9)' }}
            items={selected}
            config={{
              duration: 200,
              easing: easings.easeBackOut
            }}
          >
            {seleced => seleced && (props => <Border style={props} />)}
          </Transition>
          <Transition
            from={{ transform: 'scale(0)' }}
            enter={{ transform: 'scale(1)' }}
            leave={{ transform: 'scale(0)' }}
            items={selected}
            config={{
              duration: 200,
              easing: easings.easeBackOut,
              delay: 150
            }}
          >
            {seleced =>
              seleced &&
              (props => (
                <CheckIcon
                  style={props}
                  src={checkPng}
                  width={44}
                  height={44}
                />
              ))
            }
          </Transition>
        </Fragment>
      </Root>
    )
  }
}

export default SelectThumbnail

const Root = styled.div`
  width: ${({ type }) => (type === "large" ? '200px' : '125px')};
  height: ${({ type }) => (type === "large" ? '200px' : '125px')};
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const Border = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: ${({ theme }) => `3px solid ${theme.color.yellow}`};
  border-radius: 50%;
  pointer-events: none;
`

const CheckIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -22px 0 0 -22px;
  pointer-events: none;
`

const ImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
`

const Thumbnail = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`

const Label = styled.div`
  position: absolute;
  text-align: center;
  bottom: 20px;
  font-size: 17px;
  color: ${({ theme }) => theme.color.grey5};
  line-height: 25px;
  padding: 0 15px;
  background-color: #fff;
  border-radius: 24px;
  pointer-events: none;
  > span {
    font-family: ${({ theme }) => theme.font.english};
  }
  `
