// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as appActions } from '../redux/modules/app'
import TryList from '../components/TryList'
import { selecteFavorites } from '~/redux/modules/recommend'

const mapStateToProps = state => ({
  choicesAndKarteQrCodeUrl: state.app.choicesAndKarteQrCodeUrl,
  favoriteItems: selecteFavorites(state)
})

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TryList)
