// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as appActions } from '~/redux/modules/app'
import Home from '../components/Home'
import { fetchDigitizer,  fetchInitialize} from '~/redux/modules/home'
import { actions as webglActions } from '~/redux/modules/webgl'

const mapStateToProps = state => ({
  shoeImages: state.webgl.shoeImages,
  introImages: state.webgl.introImages
})

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions }, dispatch),
  fetchDigitizer: () => dispatch(fetchDigitizer()),
  fetchInitialize: digitizerCode => dispatch(fetchInitialize(digitizerCode)),
  registerWebglData: data => dispatch(webglActions.registerInitializeData(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
