// @flow
import React from 'react'
import styled from 'styled-components'

import { Fit, BrandName } from '../../types'
import label0Png from '~/assets/img/label-good.png'
import label1Png from '~/assets/img/label-yuttari.png'
import label2Png from '~/assets/img/label-shikkari.png'
import { addComma } from '~/helpers/number'

const labels = {
  fit: label0Png,
  loose: label1Png,
  tight: label2Png
}

type Props = {
  data: {
    maker: BrandName,
    productNumber: string,
    size: string,
    color: string,
    price: number,
    width: string,
    name: string,
    material: string
  },
  label: Fit,
  image: string
}

function Product({ data, label, image }: Props) {
  const {
    maker,
    productNumber,
    size,
    color,
    price,
    width,
    name,
    material
  } = data
  return (
    <Root>
      {label != null && (
        <Label>
          <img src={labels[label]} width={107} alt="" />
        </Label>
      )}
      <Thumbnail>
        <img src={image} alt="" />
      </Thumbnail>
      <Description>
        <p>{maker}</p>
        <p>
          {productNumber} / {size} / {width}
        </p>
        <p>{name}</p>
        <p>{`${color}/${material}`}</p>
        <Price>
          <span>{addComma(price)}</span>
        </Price>
      </Description>
    </Root>
  )
}

export default Product

const Root = styled.div`
  position: relative;
  width: 143px;
`

const Thumbnail = styled.figure`
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const Label = styled.div`
  text-align: center;
  width: 130px;
  margin: 0 auto;
  position: relative;
  top: 15px;
`

const Description = styled.div`
  text-align: center;
  margin-top: 12px;
  > p {
    font-size: 10px;
  }
`

const Price = styled.div`
  span {
    font-size: 17px;
    font-family: ${({ theme }) => theme.font.english};
    &:before {
      content: '¥';
    }
  }
  &:after {
    content: '(税抜)';
    font-size: 10px;
    margin-left: 2px;
  }
`
