// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import RefreshButton from './RefreshButton'
import Thumbnail from './thumbnail/SelectThumbnail'

type Props = {
  refresh: () => void,
  dataLayerPush: (params: { pageID: string }) => void,
  recommendActions: Object,
  precondition: Object,
  preference: string
}

export default class selectPreference extends Component<Props> {
  componentDidMount() {
    this.props.dataLayerPush({ pageID: 'selectPreference' })
  }

  isSelected = (id: string) => {
    return this.props.preference == id
  }

  handleSelect = (id: string) => () => {
    this.props.recommendActions.onSelectPreference(id)
  }

  render() {
    const { refresh } = this.props

    const items = [
      { id: "tight", name : "きつめ", pic: "/img/colors/10.png"},
      { id: "normal", name : "ふつう" , pic: "/img/colors/13.png"},
      { id: "loose", name : "ゆるめ" , pic: "/img/colors/11.png"},
    ]
    let count = 0
    return  ( 
      <Root>
      <RefreshButton onClick={refresh} />

      <Category id="preferencelist">
        {items.map(item => {
          count++
          let classNameStr = count % 2 === 0 ? 'large' : 'small'
          classNameStr += ' '
          //classNameStr += count % 3 === 2 ? 'center' : ''
          classNameStr += 'center'
          return (
            <li key={item.id} className={classNameStr}>
            { item.id && 
              <Thumbnail
                name={item.name}
                src={item.pic}
                selected={this.isSelected(item.id)}
                onSelect={this.handleSelect(item.id)}
              />
            }
            </li>
          )
        })}
      </Category>
      </Root>
    )
  }
}

const Root = styled.div`
`
const Category = styled.ul`
  opacity: 0;
  width: 955px;
  margin: 180px auto;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 180px;
  > li {
    margin-right: auto;
    margin-left: auto;
    &.center {
      margin-top: auto;
    }
    &.small.center {
      margin-bottom: 20px;
    }
  }
`
